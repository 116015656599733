import * as Sentry from "@sentry/browser";

Sentry.init({
	dsn: melon.boot.sentry_dsn,
	release: melon?.boot?.versions?.melon,
	autoSessionTracking: false,
	initialScope: {
		// don't use melon.session.user, it's set much later and will fail because of async loading
		user: { id: melon.boot.sitename },
		tags: { melon_user: melon.boot.user.name ?? "Unidentified" },
	},
	beforeSend(event, hint) {
		// Check if it was caused by melon.throw()
		if (
			hint.originalException instanceof Error &&
			hint.originalException.stack &&
			hint.originalException.stack.includes("melon.throw")
		) {
			return null;
		}
		return event;
	},
});
